<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 one-click-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="one-click-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_one-click-plan-help-online'
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: 'r_one-click-settings' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title" v-if="widgets && widgets.length">
          {{ widgets.find(el => el.widget_id === 1).name }}
        </div>
        <template v-slot:right>
          <router-link :to="{ name: 'r_one-click-plan-add' }">
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <Draggable
      v-if="plans && plans.length && mode !== 'selection'"
      :list="plans"
      item-key="id"
      tag="ul"
      @end="savePlans"
      class="clebex-item-section pill"
    >
      <template #item="{element}">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <span class="label" v-if="element.level && element.level.length">
              <span class="text">
                <span v-if="element.resource" class="highlight">{{
                  `${element.resource.name}, `
                }}</span>
                <span v-else-if="element.resource_type_id" class="highlight">{{
                  `${
                    element.resource_type_id.translations.find(
                      el => el.locale === locale
                    )
                      ? element.resource_type_id.translations.find(
                          el => el.locale === locale
                        ).attributes.name
                      : element.resource_type_id.name
                  }, `
                }}</span>
                <span v-else-if="element.resource_level_id" class="highlight">{{
                  `${
                    element.resource_level_id.translations.find(
                      el => el.locale === locale
                    )
                      ? element.resource_level_id.translations.find(
                          el => el.locale === locale
                        ).attributes.name
                      : element.resource_level_id.name
                  }, `
                }}</span>
                {{ element.level.join(", ") }}
              </span>
              <br />
              <span class="second-row-declaration"
                >{{ element.period.name }} |
                {{ getTime(element.datetime_from) }} -
                {{ getTime(element.datetime_to) }}</span
              >
            </span>
            <span class="label" v-else>
              {{ element.resource.name }}
            </span>
          </div>
        </li>
      </template>
    </Draggable>
    <Draggable
      v-if="plans && plans.length && mode === 'selection'"
      :list="plans"
      item-key="id"
      tag="ul"
      @end="savePlans"
      class="clebex-item-section pill"
    >
      <template #item="{element}">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="checkbox tiny alt">
              <input
                type="checkbox"
                :id="`resource${element.id}`"
                name="resource"
                :selected="selectedPlans.includes(element.id)"
                :value="element.id"
                @change="selectPlan(element.id)"
              />
              <label :for="`resource${element.id}`">
                <icon icon="#cx-app1-checkmark" />
              </label>
            </div>
            <span class="label" v-if="element.level && element.level.length">
              <span class="text">
                <span v-if="element.resource" class="highlight">{{
                  `${element.resource.name}, `
                }}</span>
                <span v-else-if="element.resource_type_id" class="highlight">{{
                  `${
                    element.resource_type_id.translations.find(
                      el => el.locale === locale
                    )
                      ? element.resource_type_id.translations.find(
                          el => el.locale === locale
                        ).attributes.name
                      : element.resource_type_id.name
                  }, `
                }}</span>
                <span v-else-if="element.resource_level_id" class="highlight">{{
                  `${
                    element.resource_level_id.translations.find(
                      el => el.locale === locale
                    )
                      ? element.resource_level_id.translations.find(
                          el => el.locale === locale
                        ).attributes.name
                      : element.resource_level_id.name
                  }, `
                }}</span>
                {{ element.level.join(", ") }}
              </span>
              <br />
              <span class="second-row-declaration"
                >{{ element.period.name }} |
                {{ getTime(element.datetime_from) }} -
                {{ getTime(element.datetime_to) }}</span
              >
            </span>
            <span class="label" v-else>
              {{ element.resource.name }}
            </span>
          </div>
        </li>
      </template>
    </Draggable>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list" v-if="plans && plans.length">
          <li class="action">
            <button
              class="action-btn"
              @click="setMode(mode === 'selection' ? null : 'selection')"
              :class="{ active: mode === 'selection' }"
            >
              {{ displayLabelName("one-click", "plan", "select") }}
            </button>
          </li>
          <li
            v-if="mode === 'selection' && selectedPlans.length && !processing"
            class="action"
          >
            <button class="action-btn" @click="showDeleteModal = true">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" + selectedPlans.length + "/" + plans.length + ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal delete-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("one-click.plan.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("one-click.plan.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import { getLang } from "@/services/http-service";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "OneClickPlan",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Draggable: defineAsyncComponent(() => import("vuedraggable"))
  },
  created() {
    this.getPlans();
  },
  data() {
    return {
      processing: false,
      selectedPlans: [],
      mode: null,
      showDeleteModal: false,
      helpSlug: "one-click-settings-plan"
    };
  },
  computed: {
    ...mapState("oneClick", ["plans", "widgets"]),
    locale() {
      return getLang();
    }
  },
  methods: {
    ...mapActions("oneClick", ["getPlans", "setPlanPositions", "deletePlan"]),
    setMode(mode) {
      this.mode = mode;
    },
    savePlans() {
      const postArray = [];
      let ordering = 1;
      this.plans.forEach(element => {
        postArray.push({
          id: element.id,
          ordering: ordering
        });
        ordering++;
      });
      this.setPlanPositions(postArray);
    },
    startDelete() {
      const promises = [];
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.selectedPlans.forEach(id => {
        this.processing = true;
        const promise = new Promise((resolve, reject) => {
          this.deletePlan(id)
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject(false);
            });
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        this.processing = false;
        this.mode = null;
        this.selectedPlans = [];
        this.$store.commit("loader/setScreenLoading", false, { root: true });
      });
    },
    getTime(date) {
      return dayjs(date).format("HH:mm");
    },
    selectPlan(id) {
      if (this.selectedPlans.find(element => element === id)) {
        this.selectedPlans = this.selectedPlans.filter(
          element => element !== id
        );
      } else {
        this.selectedPlans.push(id);
      }
    }
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
